@font-face {
  font-family: 'Arinoe2';
  src: url('resources/Arinoe.ttf');
}



html, body, #root, .App {
  background-color: #ff9677;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arinoe', 'Arinoe2', monospace;
                                                
}

html, body {
  scroll-behavior: smooth;
}

.main-container {
  width: 98%;
  height: 98%;
  margin: 1%;
  border-radius: 15px;
  background-color: #2a3950;
  box-sizing: border-box;
  position: fixed;
}

.main-content {
  height: 100%;
  overflow-y: auto;
  color: white;
  padding: 10px;
}

.circular-navbar {
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  background-color: transparent;
  border: solid 46px #974063;
  border-radius: 50%;
  transition: .5s;
}
.menu-item {
  font-size: 32px;
  position: absolute;
  color: white;
  text-align: center;
  text-decoration: none;
  /* transform-origin: 20px 160px; */
}

.circular-navbar span {
  
  width: 180px;
  height: 180px;
  border-radius: 50%;
  color: white;
  background-color: #41436a;
}



.menu-item.home {
  transform: translate(-86px, 110px) rotate(40deg);
}

.menu-item.games {
  transform: translate(0, 136px) rotate(0);
}

.menu-item.about {
  transform: translate(86px, 110px) rotate(-40deg);
}


.circular-navbar span a {
  text-align: center;
  color: white;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 64px;
  top: 140px;
  cursor: pointer;
}

.circ {
  position: absolute;
}

.topBg {
  z-index: 1;
  position: absolute;
  top: -150px;
  left: 0;
  width: 100%;
  height: 490px;
  transition: .5s;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='2117px' height='490px'%3E%3Cpath fill-rule='evenodd' fill='rgb(40, 53, 72)' d='M1876.1000,483.1000 C1792.548,483.1000 1718.288,440.378 1675.513,374.440 C1638.391,408.917 1588.658,429.1000 1533.1000,429.1000 C1451.592,429.1000 1380.380,382.075 1346.707,312.578 C1309.742,382.273 1234.415,429.1000 1147.500,429.1000 C1111.629,429.1000 1077.734,421.869 1047.693,407.422 C1014.376,428.074 975.081,439.1000 932.1000,439.1000 C853.093,439.1000 783.228,397.007 745.272,332.892 C710.282,381.985 652.880,413.1000 587.1000,413.1000 C537.344,413.1000 491.247,394.483 456.815,362.559 C415.709,438.455 335.375,489.1000 242.1000,489.1000 C108.795,489.1000 0.000,381.205 0.000,246.1000 C0.000,112.795 108.795,4.000 242.1000,4.000 C322.286,4.000 392.700,41.975 437.055,100.725 C472.418,56.403 526.891,28.000 587.1000,28.000 C653.283,28.000 710.994,60.415 745.921,110.027 C784.019,46.511 853.544,4.000 932.1000,4.000 C969.814,4.000 1004.495,13.127 1034.908,29.239 C1067.972,10.653 1106.442,0.000 1147.500,0.000 C1237.300,0.000 1314.729,50.947 1350.260,124.433 C1385.220,58.732 1454.385,14.000 1533.1000,14.000 C1598.247,14.000 1655.688,43.131 1693.843,88.903 C1737.866,36.966 1803.583,4.000 1876.1000,4.000 C2009.548,4.000 2116.1000,111.452 2116.1000,243.1000 C2116.1000,376.548 2009.548,483.1000 1876.1000,483.1000 Z'/%3E%3C/svg%3E");
  background-position: center;
}

@media (max-width: 360px) {
  .topBg {
    top: -215px;
  }
}

.content {
  margin-top: 220px;
  width: 100%;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #2a3950; 
}
::-webkit-scrollbar-thumb {
  background: #43669b; 
}
::-webkit-scrollbar-thumb:hover {
  background: #74a0e0; 
}

.inline-content {
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  min-height: 60vh;
  height: 100%;
  margin-bottom: 100px;
  overflow-y: hidden;
  text-align: -webkit-center;
}


.area{
  position: absolute;
  width: 100%;
  height:100vh;
  overflow: hidden;
  pointer-events: none;
 
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  
}

.circles li:nth-child(1){
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}


.circles li:nth-child(2){
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3){
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4){
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5){
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6){
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7){
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8){
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9){
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10){
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}



@keyframes animate {

  0%{
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
  }

  100%{
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
  }

}


