.page-home {
    overflow: hidden;
}




.page-home h1 {
    font-size: 8em;
    text-shadow: 2px 8px rgba(0, 0, 0, 0.637);
    margin-bottom: 0;
}

@media (max-width: 360px) {
    .page-home h1 {
      font-size: 4em;
    }
 }

.social-media ul {
    display: inline-flex;
    text-decoration: none;
    list-style: none;
    flex-flow: wrap;
    place-content: center;
    padding: 0;
}

.social-media li {
    border-radius: 10px;
    list-style: none;
    padding: 10px;
    margin: 10px;
    width: 64px;
    height: 64px;
    background-color: rgba(240, 248, 255, 0.116);
    border-bottom: 8px solid #283548;
    transition: .25s;
}


.social-media li:hover {
    border-bottom: 4px #283548;
    transform: translate(0, 4px);
}

.social-media li a {
    transition: .25s;
    color: white;
    text-decoration: none;
}
.social-media {
    width: 100%;
}

.social-media i {
    font-size: 3em;
    width: 100%;
    height: 80%;
    display: block;
}