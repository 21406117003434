

.page-about {
    text-align: -webkit-center;
    transform: translate(0, 64px);
    text-align: center;
    width: 90%;
    min-height: 60vh;
    border-radius: 15px;
    background-color: #283548;
    padding: 20px;
    margin-bottom: 100px;
}


.about-head {
    transform: translate(0, -48px);
    height: auto;
    max-width: 100%;
    max-height: 4em;
}


.about-desc {
    font-size: 2em;
}

.skillTable {
    width: -webkit-fill-available;
}

.skillTable th {
    background-color: #00000029;
    padding: 10px;
    border-radius: 15px;
}

.skillTable td {
    padding: 10px;
    background-color: #0000000d;
    border-radius: 10px;
}