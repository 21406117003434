

.page-games h1  {
    font-size: 6em;
    color: white;
    
    
}

.page-games {
    text-align: -webkit-center;

}

.games-head {
    transform: translate(0, -36px);
    height: auto;
    max-width: 100%;
    max-height: 4em;
}

.games-content {
    
    transform: translate(0, 64px);
    text-align: center;
    width: 1040px;
    border-radius: 15px;
    background-color: #283548;
    
}

.games-grid {
    grid-gap: 10px;
    display: grid;
    grid-auto-rows: 250px;
    grid-template-columns: repeat(3, 300px);
    margin-bottom: 128px;
    padding: 20px;
    justify-content: center;
}

@media screen and (max-width: 1040px) {
    .games-grid {
        grid-template-columns: repeat(auto-fit, minmax(270px, 2fr));
    }
    .games-content {
        width: 90%;
    }
}

.games-card {
    color: white;
	padding: 20px;
	text-align: center;
	font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.games-card .cardImage {
    width: 100%;
    height: 100%;
    /* background-size: cover; */
    background-position: center center;
    background-clip: border-box;
    border-radius: 25px;
    border: 4px solid #3b495e;
    background-size: cover;
    transition: .25s;
    flex: 1;
    

    
}

.games-card .cardImage:hover {
    background-size: 120%;
}
.games-card .cardImage:hover .CardHover {
    opacity: 1;
}

.games-card .cardContent {
    pointer-events: none;
    position: absolute;
    left: 25px;
    bottom: 25px;
    padding: 20px;
}
.games-card .CardHover {
    pointer-events: none;
    font-size: 18px;
    display: flex;
    position: absolute;
    justify-content: center;
    transition: .25s;
}

.games-card .CardHover div {
    pointer-events: none;
    color: rgb(209, 209, 209);
    width: 120%;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
}


.games-card .cardData {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    transition: .2s;
}

.games-card .cardCover {
    position: absolute;
    padding: 20px;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: -webkit-center;
    justify-content: center;
}

.games-card iframe {
    width: 100%;
    height: 100%;
}

.games-card .cardForeground {
    transition: .25s;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);;
}